import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/5400/1*7wgRREUzm8paSw9ZrR4uYw.png",
        "alt": null
      }}></img></p>
    <p>{`We’re improving wallet support on `}<a parentName="p" {...{
        "href": "https://app.starswap.xyz/swap"
      }}>{`app.starswap.xyz`}</a>{`. To start we’ve added support for:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://walletconnect.org/"
          }}>{`WalletConnect`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.walletlink.org/#/"
          }}>{`WalletLink`}</a>{` (Coinbase Wallet)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.portis.io/"
          }}>{`Portis`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://fortmatic.com/"
          }}>{`Fortmatic`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Mobile deep linking for `}<a parentName="p" {...{
            "href": "https://trustwallet.com/"
          }}>{`Trust Wallet`}</a></p>
      </li>
    </ul>
    <p>{`You can also now dynamically switch between wallets through the account modal, which is opened by clicking on your account at the top-right hand of the screen.`}</p>
    <h1 {...{
      "id": "walletconnect",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#walletconnect",
        "aria-label": "walletconnect permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WalletConnect`}</h1>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/2342/1*7Jkmgn-WeF8qdzJDd4sGsg.png",
        "alt": null
      }}></img></p>
    <p>{`WalletConnect allows mobile wallets to connect to your desktop browser through a QR code and sign transactions. It even works on browsers such as Safari!`}</p>
    <p>{`Adding WalletConnect support allows wallets such as `}<a parentName="p" {...{
        "href": "https://rainbow.me/"
      }}>{`Rainbow Wallet`}</a>{`, `}<a parentName="p" {...{
        "href": "https://safe.gnosis.io/"
      }}>{`Gnosis Safe`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.argent.xyz/"
      }}>{`Argent Wallet`}</a>{` (coming soon) to connect to Starswap for the first time. MetaMask mobile and Trust Wallet can also be used with wallet connect.`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/1920/1*8A9MDGkWmQnPi8g0LtVndA.gif",
        "alt": "Scan a QR code to connect your mobile wallet to app.starswap.xyz"
      }}></img></p>
    <h1 {...{
      "id": "walletlink",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#walletlink",
        "aria-label": "walletlink permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WalletLink`}</h1>
    <p>{`WalletLink functions similar to WalletConnect, using a QR code to connect your mobile Coinbase Wallet to any browser. It only needs to be linked once to work across all dApps that support it.`}</p>
    <h1 {...{
      "id": "portis",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#portis",
        "aria-label": "portis permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Portis`}</h1>
    <p>{`Portis allows users to sign into their wallet with a username / password combination in a pop-up window. They support 2FA and provide a fiat on-ramp through Wyre.`}</p>
    <h1 {...{
      "id": "fortmatic",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#fortmatic",
        "aria-label": "fortmatic permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fortmatic`}</h1>
    <p>{`Fortmatic allows users to sign into their wallet through traditional methods like email and phone in a pop-up window. They support 2FA and allow custom styling for sites that integrate.`}</p>
    <h1 {...{
      "id": "web3-react6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#web3-react6",
        "aria-label": "web3 react6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`web3-react@6`}</h1>
    <p>{`This is all built on the new v6.0 release of the `}<a parentName="p" {...{
        "href": "https://github.com/NoahZinsmeister/web3-react"
      }}>{`web3-react library`}</a>{` built by our very own `}<a parentName="p" {...{
        "href": "/NoahZinsmeister"
      }}>{`Noah Zinsmeister`}</a>{`. This release features many improvements including improved connector logic and a monorepo with dynamic imports to get your bundle sizes down. This enables us to provide great UX for seamlessly switching between wallet providers.`}</p>
    <hr></hr>
    <p>{`Do you use another wallet that’s not supported yet? `}<a parentName="p" {...{
        "href": "http://contact@starswap.xyz"
      }}>{`Let us know.`}</a></p>
    <p>{`Happy Holidays from the Starswap team!`}</p>
    <p>{`❤`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      